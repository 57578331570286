import React from 'react'
import { Link, navigate } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ListTile = props => {
    const { product } = props
    //const images = product.images.sort((a,b) => a.name > b.name ? 1:-1)
    const smTitle = product.searchTitle.length > 33 ? product.searchTitle.slice(0, 33) + "..." : product.searchTitle
    const mdTitle = product.searchTitle.length > 64 ? product.searchTitle.slice(0, 64) + "..." : product.searchTitle

    return (
        <div>
            <Link to={"/" + product.url} className="group">
                <div className='relative group-hover:border-black border border-secondary px-2 py-4 md:px-4 md:py-6 h-80 2xl:h-96 w-full'>
                    {product.images ?
                        <div>
                            <GatsbyImage
                                image={getImage(product?.images?.sort((a,b) => a.name > b.name ? 1:-1)[0]?.localFile)}
                                class="z-0 h-36 md:h-40 2xl:h-56 mb-2 2xl:mb-4"
                                objectFit='contain'
                                alt={product.searchTitle}
                            />
                        </div>
                        :
                        <div className='bg-secondary h-36 md:h-40 2xl:h-56 mb-2 2xl:mb-4' />
                    }
                    <span className="text-tertiary-light text-sm group-hover:text-primary">{product.model}</span> <br />
                    <span className='hidden 2xl:block text-sm mt-2 '>{product.searchTitle}</span>
                    <span className='hidden md:block 2xl:hidden text-sm mt-2 '>{mdTitle}</span>
                    <span className='md:hidden text-sm mt-2 '>{smTitle}</span>
                    <div className="absolute bottom-6 left-0 w-full px-3">
                        <button
                            //onClick={() => navigate("/"+product.model.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '-') + product.slug)}
                            className='md:hidden py-1 rounded text-white w-full bg-primary'
                        >
                            View Product
                        </button>
                    </div>
                </div>
            </Link>
        </div>
    )
}
export default ListTile