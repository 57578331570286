import { Link } from 'gatsby'
import React from 'react'

const Breadcrumbs = props => {
    const { trail } = props
  return (
    <div className='uppercase text-sm leading-2'>
        <Link className='hover:text-primary' to="/">Home</Link>
        {trail?.map((crumb, i, arr) => {
            return (
                <Link className={'hover:text-primary py-1 ' + (i == arr.length-1 ? 'text-primary' : '')} to={crumb.link}>
                    <span className='px-1.5'>/</span>
                    {crumb.title}
                </Link>
            )
        })}
    </div>
  )
}

export default Breadcrumbs